export const pubAptPaymentTypes = {
  tapPay: { name: 'publicAppointment.paymentTypeSettings.types.tapPay.title', value: 'tapPay', type: 'third-part' },
  newebpay: { name: 'publicAppointment.paymentTypeSettings.types.newebpay.title', value: 'newebpay', type: 'third-part' },
//   jkos: { name: '街口支付', value: 'jkos', type: 'third-part' },
//   linepay: { name: 'LINE Pay', value: 'linepay', type: 'third-part' },
}

export const feeTypes = {
  deposit: {
    label: 'publicAppointment.prePaymentSettings.feeTypes.deposit.title',
    prop: 'deposit',
    default: false,
  },
  cardSecret: {
    label: 'publicAppointment.prePaymentSettings.feeTypes.cardSecret.title',
    prop: 'cardSecret',
    help: 'publicAppointment.prePaymentSettings.feeTypes.cardSecret.help',
    default: false,
  },
  none: {
    label: 'publicAppointment.prePaymentSettings.feeTypes.none.title',
    prop: 'none',
    default: true,
  },
}
export const amountTypes = {
  fixed: { label: 'publicAppointment.prePaymentSettings.amountType.fixed.title', value: 'fixed' },
  percent: { label: 'publicAppointment.prePaymentSettings.amountType.percent.title', value: 'percent' },
}
export const invoice3rdPlatformConfig = {
  ezpay: { label: 'ezpay', value: 'ezpay' },
}

export const invoiceStatusConfig = {
  pending: { label: 'invoiceBlock.status.pending.title', value: 'pending', tag: 'info' },
  issued: { label: 'invoiceBlock.status.issued.title', value: 'issued', tag: 'action' },
  voided: { label: 'invoiceBlock.status.voided.title', value: 'voided', tag: 'info' },
  allowance: { label: 'invoiceBlock.status.allowance.title', value: 'allowance', tag: 'info' },
  markedVoided: { label: 'invoiceBlock.status.markedVoided.title', value: 'markedVoided', tag: 'danger' },
  markedAllowance: { label: 'invoiceBlock.status.markedAllowance.title', value: 'markedAllowance', tag: 'danger' },
}

export const invoiceTypeConfig = {
  C: { label: '二聯式發票', value: 'C' },
  B: { label: '三聯式發票', value: 'B' },
}

export const paymentStatusConfig = {
  pending: { label: '扣款中', value: 'pending', tag: 'warning' },
  paid: { label: '扣款成功', value: 'paid', tag: 'action' },
  failed: { label: '扣款失敗', value: 'failed', tag: 'danger' },
  refund: { label: '已退款', value: 'refund', tag: 'warning' },
}

export const depositPaymnetStatusConfig = {
  paid: { label: 'orderPubAptPaymentBlock.status.paid.title', value: 'paid', tag: 'action' },
}

export const homeModeConfig = {
  normal: { label: 'basicSetting.homeMode.normal.title', value: 'normal' },
  full: { label: 'basicSetting.homeMode.full.title', value: 'full' },
}
