<template>
  <el-dialog
    :title="$t('publicAppointment.paymentTypeSettings.dialog.view.title')"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        <div class="text-[#5E5E5E]">
          <span>{{ field.value }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ViewPubAptPaymentDialog',
  props: {
    selectPayment: {
      type: Object,
      default: () => ({}),
    },
    pubAptPaymentTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const displayData = computed(() => {
      const config = props.selectPayment
      let paymentData = []
      if (get(config, 'config.type') === 'tapPay') {
        paymentData = [
          { label: 'TapPay Partner Account', value: get(config, 'config.tapPay.partnerAccount') || '-' },
          { label: 'TapPay Partner Key', value: get(config, 'config.tapPay.partnerKey') || '-' },
          { label: 'Merchant ID', value: get(config, 'config.tapPay.merchantId') || '-' },
          { label: 'appID', value: get(config, 'config.tapPay.appID') || '-' },
          { label: 'appKey', value: get(config, 'config.tapPay.appKey') || '-' },
        ]
      }
      if (get(config, 'config.type') === 'newebpay') {
        paymentData = [
          { label: 'Merchant ID', value: get(config, 'config.newebpay.merchantId') || '-' },
          { label: 'HashKey', value: get(config, 'config.newebpay.hashKey') || '-' },
          { label: 'HashIV', value: get(config, 'config.newebpay.hashIV') || '-' },
        ]
      }
      return [
        { label: i18n.t('publicAppointment.paymentTypeSettings.verified.title'), value: config.verified ? i18n.t('publicAppointment.paymentTypeSettings.verified.success.title') : i18n.t('publicAppointment.paymentTypeSettings.verified.fail.title') },
        // { label: '啟用狀態', value: config.enable ? '開啟' : '關閉' },
        { label: i18n.t('publicAppointment.paymentTypeSettings.type.title'), value: get(config, 'config.type') ? i18n.t(get(props.pubAptPaymentTypes, get(config, 'config.type')).name) : '-' },
        { label: i18n.t('publicAppointment.paymentTypeSettings.displayName.title'), value: get(config, 'displayName') || '-' },
        ...paymentData,
      ]
    })
    return {
      displayData,
    }
  },
})
</script>

<style scoped lang="scss"></style>
