<template>
  <BaseDialog :title="$t('publicAppointment.invoiceConfigBlock.dialog.title')" width="600px" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem :label="$t('publicAppointment.invoiceConfigBlock.enable.title')" prop="enable">
        <BaseElSwitch
          v-model="formData.enable"
          :inactive-text="$t('common.close.text')"
          :active-text="$t('common.open.text')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('publicAppointment.invoiceConfigBlock.platform.title')" prop="platform">
        <BaseElSelect v-model="formData.platform" :placeholder="$t('common.input.placeholder')">
          <BaseElSelectOption
            v-for="platform in invoice3rdPlatformConfig"
            :key="platform.value"
            :value="platform.value"
            :label="platform.label"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="MerchantID" prop="merchantID">
        <BaseElInput v-model="formData.merchantID" :placeholder="$t('common.input.placeholder')" />
      </BaseElFormItem>
      <BaseElFormItem label="Hash Key" prop="hashKey">
        <BaseElInput v-model="formData.hashKey" :placeholder="$t('common.input.placeholder')" />
      </BaseElFormItem>
      <BaseElFormItem label="Hash IV" prop="hashIV">
        <BaseElInput v-model="formData.hashIV" :placeholder="$t('common.input.placeholder')" />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import store from '@/store'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { invoice3rdPlatformConfig } from '@/config/pubApt'
import { get } from 'lodash'
import {
  UpdatePubAptInvoiceConfig,
} from '@/api/pubApt'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'InvoiceConfigEditModal',
  components: { BaseDialog },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      enable: false,
      // mode: 'dev', // dev / prod
      platform: null,
      merchantID: null,
      hashKey: null,
      hashIV: null,
      // mode: null,
    })
    const formRules = {
      enable: [noEmptyRules()],
      platform: [noEmptyRules()],
      merchantID: [noEmptyRules()],
      hashKey: [noEmptyRules()],
      hashIV: [noEmptyRules()],
    }

    const compactPostData = computed(() => {
      const {
        // mode,
        enable,
        platform,
        merchantID,
        hashKey,
        hashIV,
      } = formData
      return {
        // shopId: shopId.value,
        enable,
        platform,
        invoiceApiConfig: {
          // mode,
          merchantID,
          hashKey,
          hashIV,
        },
      }
    })

    const updatePubAptInvoiceConfig = async () => {
      loading.value = true
      const [, err] = await UpdatePubAptInvoiceConfig({
        shopId: shopId.value,
        data: compactPostData.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(i18n.t('common.message.updateSuccess'))
      emit('refresh')
    }
    const syncData = () => {
      const config = props.content
      formData.enable = config.enable
      formData.platform = config.platform
      // const mode = get(config, 'invoiceApiConfig.mode')
      // if (mode) formData.mode = mode
      // if (!mode) {
      //   if (process.env.NODE_ENV === 'development') {
      //     formData.mode = 'dev'
      //   } else formData.mode = 'prod'
      // }
      formData.merchantID = get(config, 'invoiceApiConfig.merchantID')
      formData.hashKey = get(config, 'invoiceApiConfig.hashKey')
      formData.hashIV = get(config, 'invoiceApiConfig.hashIV')
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updatePubAptInvoiceConfig()
      loading.value = false
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      console.log(process.env.NODE_ENV)
      syncData()
    })

    return { formRef, formData, formRules, onConfirm, invoice3rdPlatformConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
