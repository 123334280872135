<template>
  <section :v-loading="loading" class="settings-section section-block">
    <div>
      <SectionTitle :title="$t('publicAppointment.prePaymentSettings.title')" @edit="openEditDepositDialog" />
      <div>
        <BaseElForm
          :model="displayData"
          label-position="left"
          label-width="250px"
          @submit.prevent.native="updateReservationParameter"
        >
          <BaseElFormItem :label="$t('publicAppointment.prePaymentSettings.feeType.title')">
            <span class="text-[#5E5E5E]"> {{ displayData.feeType }}</span>
          </BaseElFormItem>
          <template v-if="!useNoPayment">
            <BaseElFormItem :label="$t('publicAppointment.prePaymentSettings.feeMode.title')">
              <span class="text-[#5E5E5E]">{{ displayData.feeMode }}</span>
            </BaseElFormItem>
            <BaseElFormItem :label="$t('publicAppointment.prePaymentSettings.paymentType.title')">
              <span class="text-[#5E5E5E]">{{ displayData.paymentDataName }}</span>
            </BaseElFormItem>
            <p v-if="displayData.type === 'cardSecret'" class="text-sub text-gray-60">{{ $t('publicAppointment.prePaymentSettings.cardSecret.info.title')}}</p>
          </template>
        </BaseElForm>
      </div>
    </div>

    <EditPubAptPrePaymentDialog
      v-if="showEditDialog"
      :data.sync="formData"
      :useNoPayment="useNoPayment"
      :paymentData="paymentData"
      @close="showEditDialog = false"
      @update="updatePubAptConfig"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import EditPubAptPrePaymentDialog from './EditPubAptPrePaymentDialog.vue'
import { depositPaymentTypes } from '@/config/deposit'
import { feeTypes, amountTypes, pubAptPaymentTypes } from '@/config/pubApt'
import {
  GetDepositConfig,
  UpdateDepositConfig,
  GetDepositPayment,
} from '@/api/deposit'
import { UpdatePubAptDepositConfig } from '@/api/pubApt'
import { mapGetters } from 'vuex'
import { map, get, set } from 'lodash'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'PrePaymentSettings',
  components: { EditPubAptPrePaymentDialog },
  props: {
    content: { type: Object, default: () => ({}) },
    paymentData: { type: Array, default: () => [] },
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const useClassTicketCredit = computed(() => checkAction('admin.appointmentDepositConfig.enableClassTicketCredit'))
    const useNoPayment = computed(() => checkAction('admin.pubAptDepositConfigExtra.allowNoPayment'))
    const formData = reactive({
      type: null,
      fixedAmount: null,
      percentAmount: null,
      amountType: 'fixed',
      PubAptPaymentConfigId: null,
    })
    const displayData = computed(() => {
      const item = props.content
      const feeType = get(item, 'type') ? i18n.t(get(feeTypes, get(item, 'type')).label) : '-'
      const fixedAmount = `$${get(item, 'fixedAmount')}`
      const percentAmount = `${Math.round(get(item, 'percentAmount') * 100)}%`
      const amount = get(item, 'amountType') === 'fixed' ? fixedAmount : percentAmount
      const feeMode = get(item, 'amountType') ? i18n.t(get(amountTypes, get(item, 'amountType')).label) + amount : '-'
      const PubAptPaymentConfig = get(item, 'PubAptPaymentConfig')
      // const paymentDataItem = find(props.paymentData, { id: PubAptPaymentConfigId })
      const paymentDataName = PubAptPaymentConfig ? i18n.t(get(pubAptPaymentTypes, get(PubAptPaymentConfig, 'config.type')).name) : '-'
      return {
        ...item,
        feeType,
        feeMode,
        paymentDataName,
      }
    })
    return {
      formData,
      useClassTicketCredit,
      displayData,
      useNoPayment,
    }
  },
  data: () => ({
    loading: false,
    depositPaymentTypes,
    showEditDialog: false,
    showEditPaymentDialog: false,
    paymentDialogType: 'create',
    deleteDialog: false,
    selectPayment: null,
    depositPayments: [],
    depositConfig: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    compactData () {
      const form = this.formData
      const data = {}
      if (form.paymentMode === 'cash') {
        data.feeModel = 'serviceCount'
        data.feeModelServiceCountPrice = form.cash
      }
      if (form.paymentMode === 'percentege') {
        data.feeModel = 'totalPrice'
        data.feeModelTotalPriceRatio = form.percentege / 100
      }

      return form
    },

  },
  methods: {
    openEditDepositDialog () {
      this.syncConfigFormData()
      this.showEditDialog = true
    },
    tagType (val) {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'action'
      return type
    },
    async updatePubAptConfig (data) {
      this.showEditDialog = false
      data.percentAmount = data.percentAmount / 100
      const [, err] = await UpdatePubAptDepositConfig({
        shopId: this.shop,
        data,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success(i18n.t('common.message.updateSuccess'))
      // await this.refresh()
      this.$emit('refresh')
    },

    async getDepositPayment () {
      const [res, err] = await GetDepositPayment({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.depositPayments = res
    },

    syncConfigFormData () {
      const config = this.content
      const setData = (key, data, options = {}) => {
        this.$set(options.target || this.formData, key, data)
      }
      setData('type', config.type)
      setData('fixedAmount', config.fixedAmount)
      setData('percentAmount', config.percentAmount)
      setData('amountType', config.amountType)
      setData('PubAptPaymentConfigId', config.PubAptPaymentConfigId)
    },
  },
})
</script>

<style></style>
