<template>
  <div>
    <BaseElFormItem label="Merchant ID" prop="config.newebpay.merchantId">
      <BaseElInput v-model="syncForm.merchantId" :placeholder="`${$t('common.input.placeholder')} MerchantId`" />
    </BaseElFormItem>
    <BaseElFormItem label="HashKey" prop="config.newebpay.hashKey">
      <BaseElInput v-model="syncForm.hashKey" :placeholder="`${$t('common.input.placeholder')} HashKey`" />
    </BaseElFormItem>
    <BaseElFormItem label="HashIV" prop="config.newebpay.hashIV">
      <BaseElInput v-model="syncForm.hashIV" :placeholder="`${$t('common.input.placeholder')} HashIV`" />
    </BaseElFormItem>
  </div>
</template>

<script>
export default {
  name: 'NewePayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get() {
        return this.formData
      },
      set(v) {
        return this.$emit('update', v)
      },
    },
  },
}
</script>

<style lang="postcss" scoped></style>
